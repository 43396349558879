<template>
  <div class="container">
    <div class="row gx-3">
        <div class="col">
            <h5 align="left" class="animate__animated animate__backInLeft mt-3"><span>{{GroupName}}</span></h5>
        </div>
        <div class="col">
            <h2 align="center"><span  class="badge card-header bg-success mt-0">Define Working Days</span></h2>
        </div>
        <div class="col">
            <h5 align="right" class="animate__animated animate__backInRight mt-3"><span>{{SchoolName}}</span></h5>
        </div>      
    </div>
    <div class="row">
        <div class="col-md-2">
            <label for="SY">School Year</label>
            <select v-model="SchoolYear" @change="fetchWorkingDays(SchoolYear,GroupNo,SchoolNo)" class="form-select col-2" id="SY" >
                <option v-for="sy in SY" v-bind:value="sy.SY" :key="sy.SY">{{sy.SY}}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="from">Start Date</label>
            <input type="date" class="form-control" @change="CalcDays()" v-model = "StartDate" id="from" >
        </div>
        <div class="col-md-2">
            <label for="to">End Date</label>
            <input type="date" class="form-control" @change="CalcDays()" v-model = "EndDate" id="to" >
        </div>
        <div class="col-md-1">
            <label for="days">Days</label>
            <input disabled type="text" class="form-control" v-model = "WorkingDays" id="days" >
        </div>        
    </div>
    
    <div class="row mt-3">
        <div class="col-md-12 text-center">
          <button
            v-on:click.prevent="SaveWorkingDays()"
            class="btn btn-primary btn-lg "
          >{{operation}}</button>
        </div>
    </div> 
    <div class="row mt-3">
    <table class="table table-md table-bordered table-success table-striped">
        <thead>
          <tr class="d-flex">
            <th class="col-2 text-center">School Year</th>
            <th class="col-2 text-center">Start</th>
            <th class="col-2 text-center">End</th>
            <th class="col-2 text-center">Working Days</th>
            <th class="col-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody >
          <tr class="d-flex" v-for="WorkingDay in Working" :key="WorkingDay.SY">
            <td class="col-2 text-center">{{WorkingDay.SY}}</td>
            <td class="col-2 text-center">{{WorkingDay.StartDate}}</td>
            <td class="col-2 text-center">{{WorkingDay.EndDate}}</td>
            <td class="col-2 text-center">{{WorkingDay.WorkDays}}</td>
            <div class="btn-group col-4" role="group">
              <td class="btn btn-success" v-on:click.prevent="showEdit(WorkingDay)">Update</td>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  
  
</template>

<script>
import axios from "axios";
import server from "../server";
import SY from "../mixins/SYmixin";
import { mapState } from "vuex";
export default {
    mixins: [SY],
    data() {
        return {
            operation: "Save",
            SchoolYear: "",
            StartDate:"",
            EndDate: "",
            WorkingDays: 0,
            Working :[]
        }
    },    
    computed: {
        ...mapState(["user", "userID", "userName", "GroupNo", "GroupName", "SchoolNo", "SchoolName"]),
    },
    methods: {
        SaveWorkingDays() {
            let new_working = {
                SY: this.SchoolYear,
                GroupNo: this.GroupNo,
                SchoolNo: this.SchoolNo,
                StartDate: this.StartDate,
                EndDate: this.EndDate,
                WorkDays: this.WorkingDays
            };            
            if (this.operation === "Save" && this.SchoolYear) {

                axios.post("http://" + server.IP + ":" + server.port + "/addcallworkingday",
                new_working
                ).then(() => {{
                    this.StartDate ="";
                    this.EndDate ="";
                    this.WorkingDays = 0
                    this.fetchWorkingDays(this.SchoolYear,this.GroupNo,this.SchoolNo)
                }
                errorResponse => {
                    console.log(errorResponse);
                }
                })
            } else {
                axios.put("http://" + server.IP + ":" + server.port + "/editcalworkingday",
                new_working
                ).then(() => {{
                    this.StartDate ="";
                    this.EndDate ="";
                    this.WorkingDays = 0
                    this.operation ="Save"
                    this.fetchWorkingDays(this.SchoolYear,this.GroupNo,this.SchoolNo)
                }
                errorResponse => {
                    console.log(errorResponse);
                }
                })                
            };
            
        },        
        CalcDays() {
            if (!this.StartDate || !this.EndDate) {
                return
            }            
            this.WorkingDays = 0;
            var currentDate = new Date(this.StartDate);
            var enddate = new Date(this.EndDate)
            while (currentDate <= enddate) {
                // Skips Friday and Saturday
                if (currentDate.getDay() !== 5 && currentDate.getDay() !== 6) {
                    this.WorkingDays++;
                }
                currentDate.setDate(currentDate.getDate() + 1);
                // currentDate = currentDate.addDays(1);
            }
        },
        showEdit(WorkingDayinfo) {
        this.SchoolYear = WorkingDayinfo.SY;
        this.StartDate = WorkingDayinfo.StartDate;
        this.EndDate = WorkingDayinfo.EndDate;
        this.WorkingDays = WorkingDayinfo.WorkDays;
        // this.fetchFunctionWorkingDays();
        this.operation = "Update";
        },        
        fetchWorkingDays(sy, group, school) {
            axios.get("http://" + server.IP + ":" + server.port + "/getcalworkingdays/" +
                sy +
                "/" +
                group +
                "/" +
                school
                )
                .then(
                (data) => {
                    this.Working = data.data;
                },
                    (errorResponse) => {
                        console.log(errorResponse);
                    }
                );
        },
       fetchFunctionWorkingDays() {
            var StartDate = "02-01-2022";
            var EndDate = "02-26-2022";
            // var Startmonth = this.pad2(StartDate.getMonth()+1);//months (0-11)
            // var Startday = this.pad2(StartDate.getDate());//day (1-31)
            // var Startyear= StartDate.getFullYear();
            // var DateStart =  Startday+"-"+Startmonth+"-"+Startyear; 

            // var Endmonth = this.pad2(EndDate.getMonth()+1);//months (0-11)
            // var Endday = this.pad2(EndDate.getDate());//day (1-31)
            // var Endyear= EndDate.getFullYear();
            // var DateEnd =  Endday+"-"+Endmonth+"-"+Endyear; 

            // console.log(DateStart, DateEnd)
            axios.get("http://" + server.IP + ":" + server.port + "/getworkingdayes/" +
                StartDate +
                "/" +
                EndDate
                )
                .then(
                (data) => {
                    console.log(data.data);
                    var result = data.data[0]
                    var work = JSON.stringify(result).split(":"); 
                    console.log(work[1].slice(0, -1));
                },
                    (errorResponse) => {
                        console.log(errorResponse);
                    }
                );
        },        
        pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }
    }     
}
</script>

<style>

</style>