// import axios from "axios";
const server = {
  IP: "localhost",
  // IP: "41.39.128.131",
  port: "3000",
};
// axios
//   .get(
//     "http://ipinfo.io/json"
//   )
//   .then(
//     data => {
//       console.log(data)
//     },
//     errorResponse => {
//       console.log(errorResponse);
//     }
//   );
// console.log(window.location.hostname);
// http://ipinfo.io/json
export default server;
