.<template>
    <div class="container">
        <div class="row gx-3">
            <div class="col">
            <h5 align="left" class="animate__animated animate__backInLeft mt-3"><span>{{GroupName}}</span></h5>
            </div>
            <div class="col">
            <h2 align="center"><span  class="badge card-header bg-success mt-0">Define School Year Vacations</span></h2>
            </div>
            <div class="col">
            <h5 align="right" class="animate__animated animate__backInRight mt-3"><span>{{SchoolName}}</span></h5>
            </div>      
        </div>
        <div class="row">
                <div class="col-md-2">
                    <label for="SY">School Year</label>
                    <select v-model="Vacation.SY" @change="fetchVacations(GroupNo,SchoolNo,Vacation.SY)" class="form-select col-2" id="SY" >
                        <option v-for="sy in SY" v-bind:value="sy.SY" :key="sy.SY">{{sy.SY}}</option>
                    </select>
                </div>
            <div class="col-md-3">
                <label for="name">Vacation Name</label>
                <input type="text" class="form-control" v-model = "Vacation.Name" id="name" >
            </div>                    
            <div class="col-md-2">
                <label for="from">Start Date</label>
                <input type="date" class="form-control" @change="CalcDays()" v-model = "Vacation.StartDate" id="from" >
            </div>
            <div class="col-md-2">
                <label for="to">End Date</label>
                <input type="date" class="form-control" @change="CalcDays()" v-model = "Vacation.EndDate" id="to" >
            </div>
    
            <div class="col-md-1">
                <label for="days">Days</label>
                <input disabled type="text" class="form-control" v-model = "Vacation.Days" id="days" >
            </div>                    
        </div>
        <div class="row mt-3">
            <div class="col-md-12 text-center">
            <button
                v-on:click.prevent="SaveVacation()"
                class="btn btn-primary btn-lg "
            >{{operation}}</button>
            </div>
        </div> 
        <div class="row mt-3">
        <table class="table table-md table-bordered table-success table-striped">
            <thead>
            <tr class="d-flex">
                <th class="col-4 text-center">Vacation Name</th>
                <th class="col-2 text-center">Start</th>
                <th class="col-2 text-center">End</th>
                <th class="col-2 text-center">Days</th>
                <th class="col-2 text-center">Action</th>
            </tr>
            </thead>
            <tbody >
            <tr class="d-flex" v-for="vacation in Vacations" :key="vacation.ID">
                <td class="col-4 text-center">{{vacation.Name}}</td>
                <td class="col-2 text-center">{{vacation.StartDate}}</td>
                <td class="col-2 text-center">{{vacation.EndDate}}</td>
                <td class="col-2 text-center">{{vacation.Days}}</td>
                <div class="btn-group col-2" role="group">
                <td class="btn btn-success" v-on:click.prevent="showEdit(vacation)">Update </td>
                </div>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import server from "../server";
import SY from "../mixins/SYmixin";
import { mapState } from "vuex";
export default {
    mixins: [SY],
    data() {
        return {
            operation: "Save",
            Vacation : { 
                ID:"",
                SY: "",
                StartDate:"",
                EndDate: "",
                Name:"",
                Days: 0,
            },
            Vacations :[]
        }
    },    
    computed: {
        ...mapState(["user", "userID", "userName", "GroupNo", "GroupName", "SchoolNo", "SchoolName"]),
    },
    methods: {
        SaveVacation() {
            let new_working = {
                ID:this.Vacation.ID,
                SY: this.Vacation.SY,
                GroupNo: this.GroupNo,
                SchoolNo: this.SchoolNo,
                Name:this.Vacation.Name,
                StartDate: this.Vacation.StartDate,
                EndDate: this.Vacation.EndDate,
                Days: this.Vacation.Days
            };            
            if (this.operation === "Save" && this.Vacation.SY) {
                axios.post("http://" + server.IP + ":" + server.port + "/addVacation",
                new_working
                ).then(() => {{
                    this.Vacation.StartDate ="";
                    this.Vacation.EndDate ="";
                    this.Vacation.Name ="";
                    this.Vacation.Days = 0
                    this.fetchVacations(this.Vacation.SY,this.GroupNo,this.SchoolNo)
                }
                errorResponse => {
                    console.log(errorResponse);
                }
                })
            } else {
                axios.put("http://" + server.IP + ":" + server.port + "/editVacation",
                new_working
                ).then(() => {{
                    this.Vacation.StartDate ="";
                    this.Vacation.EndDate ="";
                    this.Vacation.Name ="";
                    this.Vacation.Days = 0
                    this.fetchVacations(this.Vacation.SY,this.GroupNo,this.SchoolNo)
                    this.operation ="Save"
                }
                errorResponse => {
                    console.log(errorResponse);
                }
                })                
            };
            
        },
        showEdit(vacation) {
        // showstudentAccounts(Studentinfo) {
            this.Vacation.ID = vacation.ID;
            this.Vacation.Name = vacation.Name;
            this.Vacation.StartDate = vacation.StartDate;
            this.Vacation.EndDate = vacation.EndDate;
            this.Vacation.Days = vacation.Days;
            this.operation = "Update";
        },                           
        CalcDays() {
            if (!this.Vacation.StartDate || !this.Vacation.EndDate) {
                return
            }
            this.Vacation.Days = 0;
            var currentDate = new Date(this.Vacation.StartDate);
            var enddate = new Date(this.Vacation.EndDate)
            while (currentDate <= enddate) {
                // Skips Friday and Saturday
                if (currentDate.getDay() !== 5 && currentDate.getDay() !== 6) {
                    this.Vacation.Days++;
                }
                currentDate.setDate(currentDate.getDate() + 1);
                // currentDate = currentDate.addDays(1);
            }
        }, 
        fetchVacations() {
            axios.get("http://" + server.IP + ":" + server.port + "/getvacations/" +
                this.Vacation.SY +
                "/" +
                this.GroupNo+
                "/" +
                this.SchoolNo
            )
            .then((data) => {
                this.Vacations = data.data;
            },
                (errorResponse) => {
                    console.log(errorResponse);
                }
            )
        },       
    }

}
</script>

<style>

</style>