import axios from "axios";
import server from "../server";

export function fetchGroups() { 
    try {
        return axios
        .get("http://" + server.IP + ":" + server.port + "/groups")
    } catch (err) {
        return err;
    }
};

export function fetchSchools(group) {
    try {
        return axios
            .get("http://" + server.IP + ":" + server.port + "/listschools/" + group)
    } catch (err) {
        return err;
    }
}
export function fetchSection(group, school) {
    try {
        return axios
        .get("http://" + server.IP + ":" + server.port + "/listsections/" + group + "/" + school);
    } catch (err) {
        return err;
    }
}
export function fetchDept(group, school, section) {
    try {
        return axios
        .get("http://" + server.IP + ":" + server.port + "/depts/" + group + "/" + school + "/" + section)
    } catch (err) {
        return err;
    }
}

export function fetchGrades(group, school, section, dept) {
    try {
        return axios
        .get("http://" + server.IP + ":" + server.port + "/grades/" +
              group + "/" + school + "/" + section + "/" + dept)
    } catch (err) {
        return err;
    }
}

export function fetchClasses(group, school, section, dept, grade) {
    try {
        return axios
        .get("http://" + server.IP + ":" + server.port + "/calasses/" +
              group + "/" + school + "/" + section + "/" + dept + "/" + grade)
    } catch (err) {
        return err;
    }
}

